<template>
  <b-container>

    <b-row class="justify-content-md-center mb-3">
      <b-col cols="12" md="auto">
        <h3>Anmeldung</h3>
      </b-col>
    </b-row>

    <b-alert
      variant="danger"
      :show="loginError"
      dismissible
    >{{ loginErrorMessage }}</b-alert>

    <b-row class="justify-content-md-center">
      <b-form>
        <b-form-group
            label="E-Mail:"
            label-for="email"
        >
          <b-form-input
              id="email"
              type="email"
              placeholder="E-Mail-Adresse"
              required
              v-model.trim="email"
          ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Passwort:"
            label-for="password">
          <b-form-input
              type="password"
              id="password"
              placeholder="Passwort"
              required
              v-model.trim="password"
          ></b-form-input>
        </b-form-group>

        <b-button
            type="button"
            variant="primary"
            class="button-primary"
            @click="sendLogin"
        >Anmelden</b-button>
        <b-link to="/passwordForgotten" class="ml-4 link-primary">Passwort vergessen</b-link>
      </b-form>
    </b-row>

  </b-container>
</template>

<script>

import axios from 'axios';
import { mapMutations, mapGetters, mapActions } from 'vuex';

export default {
  name: "areaLogin",
  data() {
    return {
      API_URL: process.env.VUE_APP_API,
      loginError: false,
      loginErrorMessage: '',
      email: '',
      password: '',
    }
  },
  mounted () {

    if (this.$store.getters.getIsLoggedIn) {
      this.$router.push('/recording');
    }

  },
  methods: {
    ...mapMutations([
    ]),
    ...mapActions([
        'setIsLoggedIn',
        'setUser',
        'setCompany',
        'setCategories',
    ]),
    ...mapGetters([
    ]),
    async sendLogin() {
      //console.log(this.email);
      //console.log(this.password);
      //console.log(this.API_URL);
      const response = await axios.post(
          this.API_URL,
          {
            type: 'login',
            email: this.email,
            password: this.password,
          }
      );

      //console.log(response);

      if (!response.data.login) {

        console.log('login fehlgeschlagen');
        //console.log(response);
        //console.log(response.data.error);
        this.loginErrorMessage = response.data.error.join("<br/>");
        this.loginError = true;

      } else {

        console.log('login erfolgreich');

        this.$store.dispatch('setIsLoggedIn', true);
        this.$store.dispatch('setUser', response.data.user);
        this.$store.dispatch('setToken', response.data.token);
        this.$store.dispatch('setCompany', response.data.company);

        this.getCategories();

        this.$router.push('/recording');

        /*
        if (response.data.user.user_level != 30) {
          this.$router.push('/recording');
        } else {
          this.$router.push('/statistic');
        }

         */


      }
    },
    async getCategories() {

      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_categories',
            cookie: cookie
          }
      );

      //console.log('checkmail');
      //console.log(response);
      //console.log('getcompanies response: ', response.data);

      this.$store.dispatch('setCategories', response.data);

    },
  }
}
</script>

<style scoped>

</style>