<template>
  <div>
    <h3>Attraktionen Monate ({{ sum_companies }} gesamt)</h3>
    <p>Angezeigt wird die Anzahl der Attraktionen, die im Zeitraum ihre Daten hinterlegt haben.<br/>(nur für Mitarbeiter und Admins sichtbar)</p>
    <vue-c3 :handler="handler" id="companiesMonths"></vue-c3>

    <div
        class="text-center mb-5"
        style="margin-top:20px; margin-left: 143px;"
    >
      <b-button
          @click="getCSV()"
          variant="outline-primary"
          class="button-outline-primary"
      >
        <font-awesome-icon
            :icon="['fa', 'file-csv']"
            class="cursor-pointer"
        /> Download
      </b-button>
    </div>

    <div
        class="text-center mb-5"
        style="margin-top:20px; margin-left: 143px;"
    >
      <b-button
          @click="getMissingData(1)"
          variant="outline-primary"
          class="button-outline-primary"
      >
        <font-awesome-icon
            :icon="['fa', 'file-csv']"
            class="cursor-pointer"
        /> fehlende Firmeneinträge
      </b-button>

    </div>

    <div
        class="text-center mb-5"
        style="margin-top:20px; margin-left: 143px;"
    >
      <b-button
          @click="getLast12Data()"
          variant="outline-primary"
          class="button-outline-primary"
      >
        <font-awesome-icon
            :icon="['fa', 'file-csv']"
            class="cursor-pointer"
        /> Auswertung 12 Monate AVG
      </b-button>

    </div>

    <div
        class="text-center mb-5"
        style="margin-top:20px; margin-left: 143px;"
    >
      <b-button
          @click="getLast12DataDetail()"
          variant="outline-primary"
          class="button-outline-primary"
      >
        <font-awesome-icon
            :icon="['fa', 'file-csv']"
            class="cursor-pointer"
        /> Auswertung 12 Monate Details
      </b-button>

    </div>

  </div>
</template>

<script>

import { mapMutations, mapGetters, mapActions } from 'vuex';
//import ChartBasic from "@/components/ChartBasic.vue";
//<chart-basic></chart-basic>
//ChartBasic

import Vue from "vue";
import VueC3 from 'vue-c3';
import 'c3/c3.min.css';
import axios from "axios";

export default {
  name: "chartSumCompaniesMonths",
  components: {
    VueC3
  },
  data() {
    return {
      API_URL: process.env.VUE_APP_API,
      handler: new Vue(),
      val: 100,
      data: {
        columns: [],
        types: []
      },
      categories: '',
      sum_companies: 0,
    }
  },
  mounted () {
    this.getChartData();
    //this.handler.$emit('init', this.options);
  },
  created() {


  },
  computed: {
    ...mapGetters([
      'getRecordingDates',
      'getUser',
      'getIsLoggedIn'
    ]),
    options() {
      return {
        bindto: '#companiesMonths',
        size: {
          width: 1200,
          height: 600,
        },
        color: {
          pattern: ['#CE1D1C','#83D0F5','#CF5A1D','#82BDF5','#CF781D','#82A9F5','#CF971D','#8295F5','#CFB51D','#8282F5','#C9CF1D','#9682F5','#ABCF1D','#A982F5']
        },
        interaction: {
          enabled: true
        },
        zoom: {
          enabled: true
        },
        data: {
          columns: this.data.columns,
          types: this.data.types,
        },
        axis: {
          x: {
            label: {
              text: 'Monate',
              /* position: 'outer-center' */
            },
            tick: {
              rotate: -30,
              multiline: false
            },
            height: 35,
            type: 'category',
            categories: this.categories,
          },
          y: {
            label: {
              text: 'Attraktionen',
              position: 'outer-middle'
            },
            tick: {
              //format: germanFormatters.numberFormat("$,")
              format: function (d) {
                return Number(d).toLocaleString("de-DE", {minimumFractionDigits: 0});
              }
            }
          }
        },
        tooltip: {
          show: true,
          grouped: true,
          contents: function (d, defaultTitleFormat, defaultValueFormat, color) {

            var $$ = this, config = $$.config,
                titleFormat = config.tooltip_format_title || defaultTitleFormat,
                nameFormat = config.tooltip_format_name || function (name) { return name; },
                valueFormat = config.tooltip_format_value || defaultValueFormat,
                text, i, title, value, name, bgcolor;
            for (i = 0; i < d.length; i++) {
              if (! (d[i] && (d[i].value || d[i].value === 0))) { continue; }

              if (! text) {
                title = titleFormat ? titleFormat(d[i].x) : d[i].x;
                text = "<table class='" + $$.CLASS.tooltip + "'>" + (title || title === 0 ? "<tr><th colspan='2'>Jahr " + title + "</th></tr>" : "");
              }

              name = nameFormat(d[i].name);
              value = valueFormat(d[i].value, d[i].ratio, d[i].id, d[i].index);
              bgcolor = $$.levelColor ? $$.levelColor(d[i].value) : color(d[i].id);

              text += "<tr class='" + $$.CLASS.tooltipName + "-" + d[i].id + "'>";
              text += "<td class='name'><span style='background-color:" + bgcolor + "'></span>" + name + "</td>";
              text += "<td class='value'>" + value + "</td>";
              text += "</tr>";
            }
            return text + "</table>";
          },
          format: {
            /*
            title: function (d) {
              return 'KW ' + d;
              },

             */
            value: function (value) {
              //function (value, ratio, id) {
              //console.log(ratio);
              //console.log(id);
              var number = Number(value).toLocaleString("de-DE", {minimumFractionDigits: 0});
              return number;
            }
          }
        },
        /*
        tooltip: {
          format: {
            title(d) {
              return `第 ${d} 期`
            },
            value(value, ratio, id) {
              return `${value} 億円`
            }
          }
        },

         */
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'bottom',
        }
      }
    },

  },
  methods: {
    ...mapMutations([
    ]),
    ...mapActions([
    ]),
    ...mapGetters([
    ]),
    growingUp() {
      this.val = this.val + 50
      console.log(this.val, 'changed')
    },
    async getChartData() {

      var cookie = this.$store.getters.getToken;
      let categories = this.$store.getters.getStatisticFilterButtons;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_chart_companies_months',
            categories: categories,
            cookie: cookie,
          }
      );

      //console.log(response);

      this.data.columns = response.data.columns;
      this.data.types = response.data.types;
      this.categories = response.data.categories;
      this.sum_companies = response.data.sum_companies;

      //console.log(this.data.columns);
      //console.log(this.data.types);
      //console.log(this.categories);

      this.handler.$emit('init', this.options);
    },
    async getCSV() {

      var filename = 'intoura_companies_monthly.csv';

      var cookie = this.$store.getters.getToken;
      let categories = this.$store.getters.getStatisticFilterButtons;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_chart_companies_months',
            categories: categories,
            cookie: cookie,
            csv: 1
          }
      ).then (response => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        link.click()
      });

      console.log(response);

    },
    async getMissingData(show_all_companies) {
      var filename = 'intoura_companies_missing_data.csv';
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'create_missing_data_statistic',
            cookie: cookie,
            csv: 1,
            show_all_companies: show_all_companies,
          }
      ).then (response => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        link.click()
      });

      console.log(response);
    },
    async getLast12Data() {
      var filename = 'intoura_statistic_last12_months.csv';
      let categories = this.$store.getters.getStatisticFilterButtons;
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_last12_months_data_statistic',
            cookie: cookie,
            categories: categories,
            csv: 1,
          }
      ).then (response => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        link.click()
      });

      console.log(response);
    },
    async getLast12DataDetail() {
      var filename = 'intoura_statistic_last12_months_detail.csv';
      let categories = this.$store.getters.getStatisticFilterButtons;
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_last12_months_data_statistic_detail',
            cookie: cookie,
            categories: categories,
            csv: 1,
          }
      ).then (response => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        link.click()
      });

      console.log(response);
    },
  },

  watch: {
    options() {
      this.handler.$emit('dispatch', chart => {
        const options = {
          /*
          columns: [
            ['ddd', 0, 20, 10, 40, 15, this.val],
            ['xxx', 30, 200, 100, 400, 150, 250]
          ]

           */
        }
        chart.load(options)
      })
    },
    /*
    turnoverWeeks() {
      this.drawChart();
    }
     */
  },

}
</script>

<style scoped>
/* klappt nicht */
.c3 line,
.c3 path {
  stroke-width: 2px;
}

</style>