const state = {

    userList: [],
    recordingDates: {},
    recordingYears: {},
    recordingMonths: {},
    recordingStaff: {},
    recordingVisitorTypes: {},
    recordingStartDate: '',
    recordingEndDate: '',
    records: {},
    statisticFilterButtons: {},
    main_categories: [],
    sub_categories: [],
    companies: {},
    companyStatistic: {},
}

const mutations = {
    SET_NEW_USER(state, payload) {
        //console.log('setze Monat: ' + payload);

        if (payload.index < 0) {

            state.userList.push(payload.user);
            //console.log(state.userList);
            return;
        }

        state.userList[payload.index] = payload.user;
    },

    SET_RECORDING_DATES(state, payload) {
        state.recordingDates = payload;
    },
    SET_RECORDING_START_DATE(state, payload) {
        state.recordingStartDate = payload;
    },
    SET_RECORDING_END_DATE(state, payload) {
        state.recordingEndDate = payload;
    },
    SET_RECORDS(state, payload) {
        state.records = payload;
    },
    SET_RECORDING_STAFF(state, payload) {
        state.recordingStaff = payload;
    },
    SET_RECORDING_VISITOR_TYPES(state, payload) {
        state.recordingVisitorTypes = payload;
    },
    SET_USERLIST(state, payload) {
        state.userList = payload;
    },
    SET_RECORDING_MONTHS(state, payload) {
        state.recordingMonths = payload;
    },

    SET_CATEGORY_ACTIVE(state, payload) {

        var type = payload.type;
        var category_id = payload.category_id;
        var active = payload.active;
        let data = {};
        var key = '';
        var idname = '';

        if (type == 'main') {
            data = state.main_categories;
            key = 'main_categories';
            idname = 'main_category_id';
        } else {
            data = state.sub_categories;
            key = 'sub_categories';
            idname = 'sub_category_id';
        }

        //console.log(category_id, idname, data);

        Object.keys(data).forEach(el => {
            //console.log(el, data[el][idname]);

            if (data[el][idname] === category_id) {
                state[key][el].active = active;
            }
        });

    },

    SET_STATISTIC_FILTER_BUTTONS(state, payload) {
        state.statisticFilterButtons = payload;
    },

    SET_RECORDING_YEARS(state, payload) {
        state.recordingYears = payload;
    },

    SET_CATEGORIES(state, payload) {
        state.main_categories = payload.main_categories;
        state.sub_categories = payload.sub_categories;
    },

    SET_COMPANIES(state, payload) {
        state.companies = payload;
    },

    SET_COMPANY_STATISTIC(state, payload) {
        state.companyStatistic = payload;
    },
}

const actions = {

    setNewUser({commit}, payload) {
        commit('SET_NEW_USER', payload);
    },

    setRecordingDates({commit}, payload) {
        commit('SET_RECORDING_DATES', payload);
    },

    setRecordingStartDate({commit}, payload) {
        //console.log(payload);
        commit('SET_RECORDING_START_DATE', payload);
    },

    setRecordingEndDate({commit}, payload) {
        commit('SET_RECORDING_END_DATE', payload);
    },

    setRecords({commit}, payload) {
        commit('SET_RECORDS', payload);
    },

    setRecordingStaff({commit}, payload) {
        commit('SET_RECORDING_STAFF', payload);
    },

    setRecordingVisitorTypes({commit}, payload) {
        commit('SET_RECORDING_VISITOR_TYPES', payload);
    },

    setUserList({commit}, payload) {
        commit('SET_USERLIST', payload);
    },

    setRecordingMonths({commit}, payload) {
        commit('SET_RECORDING_MONTHS', payload);
    },

    setCategoryActive({commit}, payload) {
        commit('SET_CATEGORY_ACTIVE', payload);
    },

    setStatisticFilterButtons({commit}, payload) {
        commit('SET_STATISTIC_FILTER_BUTTONS', payload);
    },

    setRecordingYears({commit}, payload) {
        commit('SET_RECORDING_YEARS', payload);
    },

    setCategories({commit}, payload) {
        commit('SET_CATEGORIES', payload);
    },

    setCompanies({commit}, payload) {
        commit('SET_COMPANIES', payload);
    },

    setCompanyStatistic({commit}, payload) {
        commit('SET_COMPANY_STATISTIC', payload);
    },
}

const getters = {

    userList: state => state.userList,
    /*
    userListById2: state => user_id => state.userList.find(user => user.user_id === user_id),

    userListById(user_id) {

        //console.log(user_id);

        //let userList = state.userList;

        Object.keys(userList).forEach(el => {
            //console.log(el, userList[el].user_id);
        });

    },
    */

    getUserList() {
        return state.userList;
    },
    getMainCategories() {
        return state.main_categories;
    },
    getSubCategories() {
        return state.sub_categories;
    },

    getRecordingDates() {
        return state.recordingDates;
    },
    getRecordingYears() {
        return state.recordingYears;
    },
    getRecordingStaff() {
        return state.recordingStaff;
    },
    getRecordingStartDate() {
        return state.recordingStartDate;
    },
    getRecordingEndDate() {
        return state.recordingEndDate;
    },
    getRecords() {
        return state.records;
    },
    getRecordingMonths() {
        return state.recordingMonths;
    },

    getStatisticFilterButtons() {
        return state.statisticFilterButtons;
    },

    getCompanies() {
        return state.companies;
    },

    getCompanyStatistic() {
        return state.companyStatistic;
    },
}

const userList = {
    state,
    mutations,
    actions,
    getters

}

export default userList;