/**
 * Returns the week number for this date.  dowOffset is the day of week the week
 * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
 * the week returned is the ISO 8601 week number.
 * @param int dowOffset
 * @return int
 */
Date.prototype.getWeek = function (dowOffset) {
  /*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */

  dowOffset = typeof(dowOffset) == 'number' ? dowOffset : 0; //default dowOffset to zero
  var newYear = new Date(this.getFullYear(),0,1);
  var day = newYear.getDay() - dowOffset; //the day of week the year begins on
  day = (day >= 0 ? day : day + 7);
  var daynum = Math.floor((this.getTime() - newYear.getTime() -
      (this.getTimezoneOffset()-newYear.getTimezoneOffset())*60000)/86400000) + 1;
  var weeknum;
  //if the year starts before the middle of a week
  if(day < 4) {
    weeknum = Math.floor((daynum+day-1)/7) + 1;
    if(weeknum > 52) {
      var nYear = new Date(this.getFullYear() + 1,0,1);
      var nday = nYear.getDay() - dowOffset;
      nday = nday >= 0 ? nday : nday + 7;
      /*if the next year starts before the middle of
        the week, it is week #1 of that year*/
      weeknum = nday < 4 ? 1 : 53;
    }
  }
  else {
    weeknum = Math.floor((daynum+day-1)/7);
  }
  return weeknum;
};


import Vue from 'vue'
import App from './App.vue'
import store from './store'
//import { router } from "@/routes";
//muss durch beforeEach in routes.js so gesetzt werden
import router from "@/routes";

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//fas
import { faPlay, faStop, faMicrophone, faPowerOff, faVolumeOff, faVolumeUp, faStopCircle, faPlayCircle, faWifi, faTimes, faCloudUploadAlt, faSync, faKey, faSignOutAlt, faUser, faUserGraduate, faChild, faCrown, faBaby, faUserPlus, faXmark, faCheck, faUserPen, faFileCsv } from '@fortawesome/free-solid-svg-icons'
//far
//import { faSmile, faMeh, faPauseCircle } from '@fortawesome/free-regular-svg-icons'
//fab
//import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(faPlay, faStop, faMicrophone, faPowerOff, faVolumeOff, faVolumeUp, faStopCircle, faPlayCircle, faWifi, faTimes, faCloudUploadAlt, faSync, faKey, faSignOutAlt, faUser, faUserGraduate, faChild, faCrown, faBaby, faUserPlus, faXmark, faCheck, faUserPen, faFileCsv)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

/*
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexChart', VueApexCharts)
*/

Vue.config.productionTip = false

new Vue({
  store,
  BootstrapVue,
  IconsPlugin,
  router,
  render: h => h(App),
}).$mount('#app')
