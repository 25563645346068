import { render, staticRenderFns } from "./areaPasswordForgottenActivation.vue?vue&type=template&id=694c2298&scoped=true&"
import script from "./areaPasswordForgottenActivation.vue?vue&type=script&lang=js&"
export * from "./areaPasswordForgottenActivation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "694c2298",
  null
  
)

export default component.exports