<template>
  <b-container>

    <b-row class="justify-content-md-center">
      <b-col cols="12" md="auto">
        <h3>Wöchentliche Datenerfassung</h3>
        <p>Bitte tragen Sie die folgenden Daten ein.<br/>Es werden die wöchentlichen Umsatz- und Besucherzahlen benötigt.</p>
      </b-col>
    </b-row>

    <div
        v-for="date in getRecordingDates"
        :key="date.begin_formatted"
        >
        <b-row
            class="justify-content-md-center"
            align-v="center"
            v-if="findRecord(date.year, date.week, 'turnover') == 0 || findRecord(date.year, date.week, 'visitor') == 0"
        >

          <b-col cols="8" md="auto" class="line">

            <h5>{{ date.year }}, KW {{ date.week_str}}</h5>
            <p>{{ date.begin_formatted }} - {{ date.end_formatted }}</p>

            <b-form-group
                label="Umsatz:"
                :label-for="'turnover_' + date.year + '_' + date.week_str"
                v-if="findRecord(date.year, date.week, 'turnover') == 0"
            >
              <b-input-group append-html="&euro;" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                    name="turnover"
                    :id="'turnover_' + date.year + '_' + date.week_str"
                    type="text"
                    placeholder="Umsatz"
                    :state="validation('decimal', 'turnover', date.year + '_' + date.week)"
                    v-model.trim="turnover[date.year + '_' + date.week]"
                ></b-form-input>
              </b-input-group>
            </b-form-group>

            <b-form-group
                label="Besucher:"
                :label-for="'visitors_' + date.year + '_' + date.week_str"
                v-if="findRecord(date.year, date.week, 'visitor') == 0"
            >
              <b-input-group append-html="&sum;" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                    name="visitors"
                    :id="'visitors_' + date.year + '_' + date.week_str"
                    type="text"
                    placeholder="Besucher"
                    :state="validation('number', 'visitors', date.year + '_' + date.week)"
                    v-model.trim="visitors[date.year + '_' + date.week]"
                ></b-form-input>
              </b-input-group>
            </b-form-group>

          </b-col>

        </b-row>
    </div>

    <b-row
        class="justify-content-md-center mb-3"
        align-v="center"
    >

      <b-col cols="8" md="auto">

        <b-alert
            variant="danger"
            :show="apiError"
            dismissible
        >{{ apiErrorMessage }}</b-alert>

        <b-alert
            variant="success"
            :show="apiSuccess"
            dismissible
        >Vielen Dank! Die Daten wurden gespeichert.</b-alert>

        <b-button
            type="button"
            variant="primary"
            class="mt-4 mb-4 button-primary"
            @click="saveRecords"
        >speichern</b-button>

      </b-col>

    </b-row>




  </b-container>
</template>

<script>
import axios from 'axios';
import { mapMutations, mapGetters, mapActions } from 'vuex';

export default {
  name: "areaRecordingWeeks",
  data() {
    return {
      turnover: {},
      visitors: {},
      formDataError: {},
      API_URL: process.env.VUE_APP_API,
      apiError: false,
      apiErrorMessage: '',
      apiSuccess: false,
    }
  },
  mounted () {

    if (!this.$store.getters.getIsLoggedIn) {
      this.$router.push('/login');
    }

  },
  computed: {
    ...mapGetters([
      'getRecordingDates',
      'getRecords'
    ]),
    recordingDatesCounter() {

      //let dates = this.getRecoringDates;
      //console.log(this.getRecordingDates);
      return this.getRecordingDates.length;

    },
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([]),
    ...mapGetters([
        'getToken',
        'getRecords'
    ]),
    findRecord(year, week, type) {

      let records = this.$store.getters.getRecords[type];

      //console.log(records);
      //console.log(year, week, type);

      if (typeof records === 'undefined') return 0;

      for (const key in records) {
        //console.log(key);
        //console.log(records[key]);

        if (records[key].year == year && records[key].week == week) {
          if (type === 'turnover') {

            this.turnover[year + '_' + week] = records[key].earning;

            return records[key].earning;

          } else {

            this.visitors[year + '_' + week] = records[key].visitor;

            return records[key].visitor;

          }
        }
      }
      return 0;

    },
    validation(type, area, key) {

      var ret = false;
      //var data = this.formData[type];
      var data = this[area][key];

      switch(type) {

        case 'decimal':

          //var reg = /^[0-9]*[,]*[0-9]{0,1,2}$/; /* eslint-disable-line */
          var reg = /^(\d+(?:[\.\,]\d{2})?)$/; /* eslint-disable-line */

          if (reg.test(data)) {
            ret = true;
          } else {
            ret = false;
          }

          break;

        case 'number':

          var reg = /^[0-9]*$/; /* eslint-disable-line */

          if (reg.test(data)) {
            ret = true;
          } else {
            ret = false;
          }

          break;

        default:
          ret = true;
          break;


      }

      this.formDataError[type] = ret;
      this.isFormError();
      return ret;

    },
    isFormError() {

      var form = this.formDataError;

      //console.log(form);
      if (form.length === 0) return true;

      for (const key in form) {
        //console.log(key);
        if (!form[key]) {
          //console.log(false);
          return false;
        }
      }

      return true;

    },
    saveRecords() {

      //var form = this.turnover;

      //console.log(form);
      //console.log(form.length);
      //console.log(Object.keys(form).length);
      //if (Object.keys(form).length === 0) return true;

      /*
      for (const key in form) {
        //console.log(key);
        //console.log(form[key]);
      }

       */

      this.sendRecords();


    },
    async sendRecords() {

      var cookie = this.$cookies.get('PHPSESSID');
      //console.log(cookie);

      if (cookie == null) {
        cookie = '';
      }

      cookie = this.$store.getters.getToken;

      //console.log(cookie);
      //console.log('turnover: ', this.turnover);
      //console.log(this.visitors);

      const response = await axios.post(
          this.API_URL,
          {
            type: 'set_records',
            turnover: this.turnover,
            visitor: this.visitors,
            cookie: cookie,
          }
      );

      //console.log(response);

      if (response.data.error) {

        //console.log(response);
        //console.log(response.data.error);
        this.apiErrorMessage = response.data.error.join("<br/>");
        this.apiError = true;
        this.apiSuccess = false;

      } else {

        this.apiSuccess = true;
        //this.$store.dispatch('setIsLoggedIn', true);
        //this.$store.dispatch('setUser', response.data.user);

        //this.$router.push('/recording');

      }
    },
  }
}
</script>

<style scoped>

.line {
  border-bottom: 2px solid #CCE5FF;
  padding-bottom: 20px;
  padding-top: 20px;
}

</style>